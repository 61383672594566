@import "styles/variables";

$modal_breakpoint: 1024px;

.mobile_bottom_actions {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	background-color: transparent;
	z-index: 99;
	height: 100%;
	margin-top: 0.5rem;
}

.background {
	background-color: #202020;
	padding-top: 1rem;
}

.mobile_bottom_button {
	border-radius: 32px;
	padding: 1rem 0.5rem;
	border: 0;
	height: 3rem;
	width: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mobile_choose_traits {
	font-family: "Konnect";
	border-radius: 32px;
	padding: 1rem 0.5rem;
	text-align: center;
	border: 0;
	height: 58px;
	width: 55%;
	color: black;
	font-weight: 600;
	font-size: 13px;
	background-color: white;
}

.pane {
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 40%;
	background-color: #202020;
	right: 0;
	top: 0;
	height: 100%;
	color: white;
	transition: 1s;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.active_category {
	background-color: #404040;
}

.item_details_section {
	font-family: "Konnect";
	height: 18%;
	display: flex;
	justify-content: flex-end;
	background-size: 65%;
	background-repeat: no-repeat;
	background-position-x: -35%;
	background-position-y: 15%;
}

.item_details_container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 65%;
	text-align: left;

	@media (max-width: $modal_breakpoint) {
		margin: 0 auto;
		height: 6rem;
	}
}

.bottom_primary {
	width: 100%;
	display: flex;
	align-items: center;
	background-color: transparent;
	flex-direction: column;
	position: absolute;
	bottom: 0;
	z-index: 9999;
	height: auto;
	justify-content: flex-end;
	margin-bottom: 0.8rem;
}

.item_details_title_container {
	margin-top: 1rem;
}

.item_details_title {
	font-size: 1.7rem;
	font-weight: 500;
	line-height: 100%;
}

.item_details_collection {
	margin-top: 1rem;
	font-weight: 300;
}

.item_details_top_container {
	display: flex;
	line-height: 100%;
}

.item_details_rarity {
	font-size: 1rem;
	padding: 4px 10px;
}

.item_details_equipped {
	color: #9d9d9d;
}

.items_section_parent {
	border: 1px solid #404040;
	width: 100%;
	height: 100%;
}

.items_section_container {
	height: 100%;
	overflow-y: auto;
}

.main_container {
	display: flex;
	flex-direction: row;
	font-family: "Konnect";
	height: 82%;
}

.categories {
	width: 14rem;
}

.checkmark {
	position: absolute;
	width: 15%;
	height: 15%;
	top: 10%;
	right: 10%;
	z-index: 100;

	@media (max-width: $modal_breakpoint) {
		top: 2%;
	}
}

.checkmark_img {
	height: 100%;
	width: 100%;
}

.category {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 3rem;
	border: 1px solid #404040;
	align-items: center;
	padding: 0 1.2rem;
	font-size: 0.8rem;
	cursor: pointer;
	border-left: 0px;
	border-bottom: 0px;
	border-right: 0px;
}

.icon_category {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.icon {
	margin-right: 1rem;
	opacity: 0.5;
}

.items {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		margin: 0 2rem;
	}
	@media (max-width: $breakpoint-mobile) {
		margin-top: 1.8rem;
	}
}

.item_parent {
	position: relative;
	opacity: 0.6;
	transition: opacity 0.3s;
	margin: 0.5rem 1rem;
	height: 10rem;
	width: 10rem;

	&:hover {
		opacity: 1;
	}

	@media (max-width: $breakpoint-mobile) {
		margin: 0;
	}
}

.item_parent_placeholder {
	position: relative;
	opacity: 1;
	transition: opacity 0.3s;
	margin: 0.5rem 1rem;
	height: 10rem;
	width: 10rem;

	&:hover {
		opacity: 1;
	}

	@media (max-width: $breakpoint-mobile) {
		margin: 0;
	}
}

.item_active {
	opacity: 1;
}

.corner_icon {
	position: absolute;
	top: 10%;
	right: 10%;
}

.item {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem;
	height: 100%;
}

.placeholder {
	padding: 0.2rem;

	img {
		width: 100%;
		height: auto;
	}
}

.equip_placeholder_active {
	opacity: 100%;
}

.equip_placeholder_mouseover {
	opacity: 80%;
}

.equip_placeholder_inactive {
	opacity: 60%;
}
