.main_container {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	width: 100%;
}

.infinite_scroll_filter_closed {
	width: 100%;
}

.infinite_scroll_filter_open {
	width: 100% - 18%;
}

.filter_collapse_container {
	position: fixed;
	left: 3rem;
	top: 50vh;
	cursor: pointer;
}

.filter_collapse {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: transparent;
	height: 100vh;
}

.sidebar_container {
	color: white;
	background-color: transparent;
	width: 15vw;
	height: 95vh;
	overflow-y: auto;
}

.right_icons_container {
	color: white;
	background-color: transparent;
}
