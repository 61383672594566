@charset "UTF-8";

/// Devices
// Device = Most of the Smartphones Mobiles (Portrait)
$screen-xxs-min: 320px;
$screen-xxs-max: 480px;

// Device = Low Resolution Tablets, Mobiles (Landscape)
$screen-xs-min: 481px;
$screen-xs-max: 767px;

// Device = Tablets, Ipads (portrait)
$screen-sm-min: 768px;
$screen-sm-max: 1024px;

// Device = Laptops, Desktops
$screen-md-min: 1025px;
$screen-md-max: 1280px;

// Device = Desktops
$screen-lg-min: 1281px;
$screen-lg-max: 1440px;

// Higher Resolution Screens
$screen-xlg-min: 1441px;
$screen-xlg-max: 1600px;

// xHigher Resolution Screens
$screen-xxlg-min: 1601px;
$screen-xxlg-max: 2560px;

$screen-s4-desktop: 2000px;
$screen-xlg-container: 1920px;

// ** Breakpoint layout

@mixin iphone5() {
	@media only screen and (min-width: $screen-xxs-min) {
		@content;
	}
}

@mixin iphone6() {
	@media only screen and (min-width: 375px) {
		@content;
	}
}

@mixin mobile() {
	@media only screen and (min-width: $screen-xs-min) {
		@content;
	}
}

@mixin tablet() {
	@media only screen and (min-width: $screen-sm-min) {
		@content;
	}
}

@mixin tablet169() {
	@media only screen and (min-width: 991px) {
		@content;
	}
}

@mixin ipad-pro() {
	@media only screen and (min-width: $screen-md-min) {
		@content;
	}
}

@mixin desktop() {
	@media only screen and (min-width: $screen-lg-min) {
		@content;
	}
}

@mixin big-desktop() {
	@media only screen and (min-width: $screen-xlg-min) {
		@content;
	}
}

@mixin xbig-desktop() {
	@media only screen and (min-width: $screen-xxlg-min) {
		@content;
	}
}

@mixin s4k-desktop() {
	@media only screen and (min-width: $screen-s4-desktop) {
		@content;
	}
}

@mixin media_custom_max($maxwidth) {
	@media only screen and (max-width: $maxwidth) {
		@content;
	}
}

@mixin media_custom_min($minwidth) {
	@media only screen and (min-width: $minwidth) {
		@content;
	}
}

@mixin bs-tablet() {
	@media only screen and (min-width: $screen-sm-min) {
		@content;
	}
}

@mixin bs-desktop() {
	@media only screen and (min-width: $screen-md-min - 1) {
		@content;
	}
}

@mixin only-mobile() {
	@media only screen and (max-width: $screen-xs-min) {
		@content;
	}
}

@mixin only-tablet() {
	@media only screen and (max-width: $screen-sm-min) {
		@content;
	}
}

@mixin only-tablet169() {
	@media only screen and (max-width: $screen-md-min) {
		@content;
	}
}
