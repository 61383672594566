@import "styles/variables";
$modal_breakpoint: 1024px;

.grid_container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: minmax(100px, auto);
	justify-items: center;
	align-items: center;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-left: 1px solid rgba(255, 255, 255, 0.2);

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		grid-auto-rows: minmax(auto, auto);
	}

	@media (max-width: $breakpoint-mobile) {
		grid-auto-rows: minmax(auto, auto);
	}
}

.file_container {
	text-align: center;
	cursor: pointer;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	margin: 0 0rem;
	margin-bottom: 0rem;
	width: 100%;
	height: 100%;
	aspect-ratio: 1/1;

	&:hover > div > div > .file_text {
		display: block;
	}
}

.files_view_container {
	padding-left: 1rem;
	padding-right: 1rem;
}

.header {
	display: flex;
	justify-content: space-between;
	color: white;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8rem;
	padding: 0 0.5rem;

	@media (max-width: $modal_breakpoint) {
		font-size: 0.6rem;
	}
}

.thumbnail {
	position: relative;
	height: 100%;
	width: 100%;
}

.thumbnail > div:nth-child(2) {
	display: block !important;
}

.file_text {
	display: none;
	font-family: "Konnect";
	font-weight: 500;
	letter-spacing: 0.5px;
	text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.475);
	font-size: 0.8rem;
	color: white;
	text-transform: uppercase;
	z-index: 10;

	@media (max-width: $breakpoint-tablet) {
		display: block;
		font-size: 0.6rem;
	}
}

.file_label_container {
	position: absolute;

	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty_image {
	background-color: transparent;
	height: 100%;
	width: 100%;
	aspect-ratio: 1/1;
}

hr.thick {
	color: white;
	border: 1px solid white;
	margin: 0.4rem 0.5rem;
}

.loading {
	color: white;
	text-transform: uppercase;
	margin-left: 1rem;
}

.files_button {
	background-color: #b0861a;
	color: white;
	font-weight: bold;
	border: 0;
	padding: 8px;
	text-transform: uppercase;
	letter-spacing: 5px;
	white-space: nowrap;
	border-radius: 5px;
	white-space: normal;
	margin-top: 5px;
	font-size: 0.4em;
	@media (min-width: 400px) {
		padding-left: 18px;
		padding-right: 18px;
		font-size: 0.5em;
	}
}

.download_container {
	margin-top: 10px;
	margin-left: 6px;
	margin-right: 6px;
}

.wallet_container {
	position: absolute;
	bottom: 0;
	margin-bottom: 10px;
}

.files_disconnect {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	font-weight: bold;
	border: 2px solid white;
	padding: 8px;
	text-transform: uppercase;
	letter-spacing: 5px;
	white-space: nowrap;
	border-radius: 5px;
	font-size: 35%;
}

.connect_container {
	padding: 15px;
}

.disconnect_container {
	display: flex;
	justify-content: center;
}

.get_asset_container {
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.files_text {
	color: white;
	font-size: 0.45em;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1px;
	@media (min-width: 400px) {
		font-size: 0.65em;
	}
}

.files_note {
	margin-top: 2em;
	color: white;
	font-size: 0.6em;
	text-transform: uppercase;
}

.files_downloads {
	display: flex;
	justify-content: space-around;
	margin-top: 1em;
	margin-bottom: 1em;
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
}

.image {
	max-height: 125px !important;
}
