@import "styles/variables";

$modal_breakpoint: 1024px;

.close_button {
	position: absolute;
	bottom: 0;
	right: 0;

	border: 0;
	padding: 0;
	margin: 0;
	padding: 1rem;

	background-color: transparent;
	color: white;
}

.equip_pane {
	margin-left: 0;
}

.sub_modal_container {
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: flex-end;
	background-color: rgba(255, 255, 255, 0.7);
}

.sub_modal_background_image {
	position: absolute;
	z-index: -1;
	width: 100vh;
	left: -100%;
	height: 100%;
	bottom: 0%;
	width: 100vh;
}

.mobile_inventory_container {
	position: relative;
	width: 75%;
	height: 100%;
}

.mobile_inventory_tab_container {
	width: 25%;
	flex-direction: column;
	padding-top: 2rem;
}

.unavail_tabs_container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.wallet_container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.wallet_inner_container {
	position: absolute;
	top: 35%;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 15rem;
	z-index: 100;
}

.equip_view_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 1.5rem;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		margin-top: 0;
	}

	@media (max-width: $breakpoint-mobile) {
		margin-top: 0;
	}
}

.equip_view_inner_container {
	width: 45vh;
	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		width: 100%;
	}

	@media (max-width: $breakpoint-mobile) {
		width: 100%;
	}
}

.unselected_tab {
	opacity: 40%;
}

.equipped_parent {
	width: 100%;
	font-family: "Konnect";
	font-weight: 300;
}

.equipped_container_size {
	width: 100%;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		width: 100%;
	}

	@media (max-width: $breakpoint-mobile) {
		width: 100%;
	}
}

.equipped_container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: minmax(auto, auto);
	background-color: transparent;
	height: 100%;

	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.equipped_item_size {
	aspect-ratio: 1/1;
}

.equipped_item_size_2 {
	aspect-ratio: 2/1;
}

.equipped_item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.top_left {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.equipped_item > div > div {
	display: block !important;
}

.equipped_item_badge {
	align-items: flex-start;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		align-items: center;
	}
}

.no_border_left {
	border-left: none;
}

.no_border_top {
	border-top: none;
}

.sub_equipped_item_size {
}

.equipment_container {
	color: black;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	height: 80%;
	width: 100%;
	align-items: center;
	text-align: center;

	@media (max-width: $modal_breakpoint) {
		padding-top: 1rem;
	}

	@media (min-width: $modal_breakpoint) {
		height: 88%;
	}
}

.inventory_container {
	position: relative;
	// height: 30%;
	overflow: auto;
	aspect-ratio: 2/1;

	@media (max-width: $breakpoint-tablet) {
		flex-direction: column;
		align-items: center;
	}
}

.available_inventory_container {
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (max-width: $breakpoint-tablet) {
		flex-direction: column;
	}
}

.badge_name {
	display: inline-block;
	font-size: 0.6rem;
	text-transform: uppercase;
	color: white;
}

.none {
	margin-top: 10px;
}

.inventory {
	display: flex;
	position: relative;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: transparent;
	justify-content: center;
	align-items: baseline;

	width: 100%;
	height: 100%;
}

.box_header {
	background-color: white;
	text-transform: uppercase;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
	font-size: 0.4rem;
	font-weight: bold;
	letter-spacing: 3px;
}

.inventory_header {
	display: flex;
	height: 7%;
	justify-content: center;
	align-items: center;
}

.negative_z {
	z-index: -1;
}

.box_decoration {
}

.inventory_box_decoration {
}

.equip_panel {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 28%;
}

.wearables_header {
	display: flex;
	height: 15%;
	justify-content: center;
	align-items: center;
}

.wearables_box {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	height: 100%;
}

.wearables_box_decoration {
	padding-bottom: 85%;
	width: 100%;
	height: 80%;
	position: relative;

	@media (max-width: 1150px) {
		padding-bottom: 70%;
	}

	@media (max-width: $modal_breakpoint) {
		padding-bottom: 50%;
	}
}

.reset_button {
	color: transparent;
	position: relative;
	padding-bottom: 25%;
	width: 100%;
	border: 1px solid white;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border-radius: 18px;
	cursor: pointer;
}

.equipped_wristband {
	position: absolute;
	width: 70%;
	top: 50%; /* Position Y halfway in */
	left: 50%; /* Position X halfway in */
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.equipped_badge_alter {
	width: 80%;
	height: 100%;

	@media (max-width: $modal_breakpoint) {
		width: auto;
	}

	@media (max-width: 500px) {
		width: 60%;
	}

	@media (max-width: 400px) {
		width: 50%;
	}
}

.lock {
	opacity: 20%;
}

.equipment_inner_container {
	width: 100%;
	height: 100%;
	position: relative;
	display: grid;
	grid-template-columns: repeat(6, auto);
	grid-auto-rows: auto;
	background-color: transparent;
	overflow-x: hidden;

	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-left: 1px solid rgba(255, 255, 255, 0.2);

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		width: 100%;
	}

	@media (max-width: $breakpoint-mobile) {
		grid-template-columns: repeat(3, auto);
	}
}

.item {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	z-index: 100;
	aspect-ratio: 1/1;

	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	border-right: 1px solid rgba(255, 255, 255, 0.2);

	@media (mine-width: $breakpoint-tablet) {
		width: 100%;
		height: 100%;
	}
}

.no_pointer {
	cursor: default;
}

.type_menu {
	display: flex;
	flex-direction: row;
}

.empty_wristband {
	font-family: "Konnect";
	font-weight: 300;
}

.empty {
	color: white;
	font-family: "Konnect";
	font-style: normal;
	font-weight: 500;
	font-size: 9px;
	line-height: 11px;
	text-align: center;
	letter-spacing: 3px;
}

.tab {
	width: 1.6rem;
	cursor: pointer;
	margin-right: 1.5rem;
	margin-bottom: 0.6rem;
	transition: all 150ms ease-out;

	&:hover {
		transform: scale(1.1);
		transition: all 150ms ease-in;
	}

	@media (max-width: $breakpoint-tablet) {
		margin-right: 0;
	}
}

.tab_opacity {
	width: 1.6rem;
	margin-bottom: 0.6rem;
	opacity: 0.4;
	margin-right: 1.5rem;

	@media (max-width: $breakpoint-tablet) {
		margin-right: 0;
	}
}

.disabled_tab {
	cursor: not-allowed;
}

.cursor_pointer {
	cursor: pointer;
}

.equipped_on_other_hape {
	opacity: 40%;
	cursor: not-allowed;
}

.wristband_container {
	position: relative;
	width: 90%;
	height: 90%;
}

.wristband_equip_check {
	position: absolute;
	width: 20%;
	height: 20%;
	bottom: 10%;
	right: 0%;
	z-index: 100;
}

.badge_equip_check {
	position: absolute;
	width: 20%;
	height: 20%;
	bottom: 10%;
	right: 0%;
	z-index: 100;
}

.item_shadow {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.status_icon {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
