@import "styles/variables";

// Helpers
@import "../../../styles/global/helpers.scss";

.card_container {
	width: fit-content;
}

.grid {
	outline: none;
	float: right;
}

.grid_container {
	@media (max-width: $breakpoint-mobile) {
		margin-top: 2.8rem;
	}
}
