@import "styles/variables";
$modal_breakpoint: 1024px;

.container {
	margin-top: 1rem;
	@media (max-width: $breakpoint-tablet) {
		width: 100%;
	}
}

.closet {
	border: 0;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 25px;
	background-color: rgb(255, 255, 255);
	color: black;

	@media (max-width: $breakpoint-tablet) {
		font-family: "Konnect";
		border-radius: 32px;
		padding: 1rem 0.5rem;
		text-align: center;
		border: 0;
		height: 58px;
		width: 55%;
		font-weight: 600;
		font-size: 13px;
	}
}

.equip_wallet_container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	cursor: pointer;
}

.wallet_icon {
	justify-content: center;
	align-items: center;
	display: flex;
	width: 2rem;
	height: 2rem;
}

.text_container {
	font-family: "Konnect";
	text-align: center;
	color: black;
}

.top_text {
	font-family: "Konnect";
	font-weight: 800;
	font-size: 1rem;
	color: white;
	margin-bottom: 0.5rem;
}

.bottom_text {
	font-family: "Konnect";
	font-weight: 300;
	font-size: 0.9rem;
	color: white;
	margin-bottom: 0.5rem;
}

.open_wallet {
	border-radius: 25px;
	padding: 0.2rem 1rem;
	background-color: rgb(255, 255, 255);
	color: black;

	@media (max-width: $breakpoint-tablet) {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: "Konnect";
		border-radius: 32px;
		padding: 1rem 0.5rem;
		text-align: center;
		border: 0;
		height: 58px;
		font-family: "Konnect";
		width: 55vw;
		font-weight: 600;
		font-size: 13px;
	}
}

.center {
	text-align: center;
	filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.2));
}

.grid_container {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.header {
	display: flex;
	justify-content: space-between;
	color: white;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8rem;
	padding: 0 0.5rem;

	@media (max-width: $modal_breakpoint) {
		font-size: 0.6rem;
	}
}

.thumbnail {
	position: relative;
}

.thumbnail > div:nth-child(2) {
	box-shadow: rgba(45, 45, 45, 0.16) 0px 3px 6px,
		rgba(43, 43, 43, 0.23) 0px 3px 6px;
}

.download {
	position: absolute;
	width: 20%;
	right: 5%;
	bottom: 5%;
	z-index: 100;
}

hr.thick {
	color: white;
	border: 1px solid white;
	margin: 0.4rem 0.5rem;
}

.file_container {
	width: 20%;
	text-align: center;
	margin: 0 0.5rem;
	margin-bottom: 1rem;
	cursor: pointer;
	@media (max-width: $modal_breakpoint) {
		margin: 0 0.3rem;
		margin-bottom: 0.6rem;
		width: 22%;
	}
}

.file_text {
	font-size: 0.6rem;
	color: white;
	text-transform: uppercase;
	@media (max-width: $modal_breakpoint) {
		font-size: 0.5rem;
	}
}

.loading {
	color: white;
	text-transform: uppercase;
}

.files_button {
	background-color: #b0861a;
	color: white;
	font-weight: bold;
	border: 0;
	padding: 8px;
	text-transform: uppercase;
	letter-spacing: 5px;
	white-space: nowrap;
	border-radius: 5px;
	white-space: normal;
	margin-top: 5px;
	font-size: 0.4em;
	@media (min-width: 400px) {
		padding-left: 18px;
		padding-right: 18px;
		font-size: 0.5em;
	}
}

.download_container {
	margin-top: 10px;
	margin-left: 6px;
	margin-right: 6px;
}

.wallet_container {
	position: absolute;
	bottom: 0;
	margin-bottom: 10px;
}

.files_disconnect {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	color: white;
	font-weight: bold;
	border: 2px solid white;
	padding: 8px;
	text-transform: uppercase;
	letter-spacing: 5px;
	white-space: nowrap;
	border-radius: 5px;
	font-size: 35%;
}

.connect_container {
	padding: 15px;
}

.disconnect_container {
	display: flex;
	justify-content: center;
}

.get_asset_container {
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.files_text {
	color: white;
	font-size: 0.45em;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1px;
	@media (min-width: 400px) {
		font-size: 0.65em;
	}
}

.files_note {
	margin-top: 2em;
	color: white;
	font-size: 0.6em;
	text-transform: uppercase;
}

.files_downloads {
	display: flex;
	justify-content: space-around;
	margin-top: 1em;
	margin-bottom: 1em;
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
}

.image {
	max-height: 125px !important;
}
