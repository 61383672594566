@import "styles/variables";
.equipment_grid {
	position: fixed;
	top: 2.8rem;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #202020;
	color: white;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		top: 0%;
	}
}

.gradient_background {
	background-image: linear-gradient(black, #4c4c4c);
}

.inner_container {
	text-align: center;
	font-family: "Konnect";
	height: 80%;
}

.grid {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.title {
	margin-top: 15%;
	margin-bottom: 5%;
}

.icon {
	width: 2.5rem;
	height: 2.5rem;

	img {
		opacity: 0.5;
		height: 100%;
		width: 100%;
	}
}

.icon_category {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 1rem;
}

.category {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	aspect-ratio: 1/1;
	height: auto;
	margin: 2%;
	background-color: #282828;
}

.category_title {
	position: absolute;
	bottom: 12%;
	font-size: 0.7rem;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		font-size: 1rem;
	}
}
